/*
 * @Descripttion:
 * @Author: Lorder
 * @Date: 2019-12-03 14:53:45
 * @LastEditors  : Lorder
 * @LastEditTime : 2020-01-13 16:19:59
 * Modified by Little White on March 16th, 2020
 */
import request from '@/utils/request';

// 查询保险数据列表
export async function getInsurance(body: any) {
  return request({ url: 'insurance/queryQuestExam', body });
}

// 导出保险数据
export async function exportInsuranceData(body: any) {
  return request({ url: 'insurance/exportQuestExam', body });
}

// 更改保险数据状态
export async function updateInsuranceStatus(body: any) {
  return request({ url: 'insurance/updateStatus', body });
}

/**
 * 新增保险列表
 */
// 查询保险列表
export async function queryInsuranceList(body: any) {
    return request({ url: 'insuranceLinks/getInsuranceInfoLinkList', body });
}

// 新建单课
export async function addInsurance(body: any) {
    return request({ url: 'insuranceLinks/create', body });
}

// 查询保险作业列表
export async function getCampeDateExamAnswerList(body: any) {
  return request({ url: 'exam/getCampeDateExamAnswerList', body });
}

// 导出作业详情
export async function exportCampDateExamAnswer(body: any) {
  return request({ url: 'exam/exportCampDateExamAnswer', body });
}

// 签到导出
export async function exportCampDateExamPaper(body: any) {
  return request({ url: 'exam/exportCampDateExamPaper', body });
}

// 查询保险教师列表
export async function queryTeacherList(body: any) {
  return request({ url: 'insuranceLinks/getInsuranceTeacher', body });
}


// 批量分班操作人与期列表
export async function getOperatorAndCampDate(body: any) {
    return request({ url: 'insurance/getOperatorAndCampDate', body });
}

// 批量分班列表搜索
export async function getInsuranceDistributeRecordList(body: any) {
    return request({ url: 'insurance/getInsuranceDistributeRecordList', body });
}

// 批量分班查询可分配
export async function allocationStudentsNum(body: any) {
    return request({ url: 'insurance/allocationStudentsNum', body });
}

// 批量分班获取保险营期配额
export async function queryInsuranceCampDate(body: any) {
    return request({ url: 'insurance/queryInsuranceCampDate', body });
}

// 批量分班获取保险班级配额
export async function queryInsuranceClassSumNum(body: any) {
    return request({ url: 'insurance/queryInsuranceClassSumNum', body });
}

// 批量分班保存
export async function batchChooseInsuranceCampDate(body: any) {
    return request({ url: 'insurance/batchChooseInsuranceCampDate', body });
}
