// eslint-disable-next-line max-len
import {
  getLiveTerm,
  getLiveInter,
  queryEditLiveTerm,
  updateLiveTerm,
  queryPackageCourses,
  openPopups,
  closePopups,
  queryLiveStudent,
  queryLiveStudentChatInfo,
  queryLiveStudentChatInfoDetail,
  queryUserGrade,
  queryPlayBackInfo,
  exportExcelLiveStudent,
  randomVistors,
  virtualSignUp,
  batchVirtualSignUp,
  packageCoursesByTalkFunCourseId,
  selectLiveCourseGroupList,
} from '@/services/live';
import { error, exportExcel, success } from '@/utils/utils';
import moment from 'moment';

// @ts-ignore
export default {
  namespace: 'live',
  state: {
    pagination: (<any>window).GLOBAL.DEFAULT_PAGINATION, // eslint-disable-line no-undef
    liveTermList: [],
    liveTermForEdit: {},
    packageCourse: [],
    liveStudentList: [],
    selectTagTypeList: [],
  },

  effects: {
    // 查询直播期列表
    *liveTermList({ payload }: any, { call, put }: any) {
      const response = yield call(getLiveTerm, payload);
      const { data } = response;
      const { dataList, totalCount } = data;
      if (response.code === 0) {
        yield put({
          type: 'redList',
          payload: {
            liveTermList: dataList,
            pagination: {
              ...payload,
              total: totalCount,
            },
          },
        });
      }
    },

            // 查询直播课程组列表
            * liveCourseList({ payload }: any, { call, put }: any) {
              const response = yield call(selectLiveCourseGroupList, payload);
              const { data } = response;
              const { dataList, totalCount } = data;
              if (response.code === 0) {
                yield put({
                  type: 'redList',
                  payload: {
                    liveTermList: dataList,
                    pagination: {
                      ...payload,
                      total: totalCount,
                    },
                  },
                })
              }
            },
    // 查询直播互动
    *liveInterList({ payload }: any, { call, put }: any) {
      const response = yield call(getLiveInter, payload);
      const { data } = response;
      const { dataList, totalCount } = data;
      if (response.code === 0) {
        yield put({
          type: 'redList',
          payload: {
            liveTermList: dataList,
            pagination: {
              ...payload,
              total: totalCount,
            },
          },
        });
      }
    },

    // 获取编辑直播期列表
    // eslint-disable-next-line consistent-return
    *editLiveTermList({ payload }: any, { call }: any) {
      const response = yield call(queryEditLiveTerm, payload);
      if (response.code === 0) {
        return response.data;
      }
    },

    // 提交编辑直播期
    // eslint-disable-next-line consistent-return
    *updateLiveTerm({ payload }: any, { call }: any) {
      try {
        const response = yield call(updateLiveTerm, payload);
        if (response.code === 0) {
          return true;
        }
      } catch (err) {
        return false;
      }
    },

    // 查询打包推广课
    // * packageCourse({ payload }: any, { call, put }: any) {
    //   const response = yield call(queryPackageCourses, payload);
    //   if (response.code === 0) {
    //     const { data } = response;
    //     yield put({
    //       type: 'redList',
    //       payload: {
    //         packageCourse: data,
    //       },
    //     })
    //     return data||true
    //   }
    //   return false
    // },

    // 查询打包推广课
    *packageCourse({ payload }: any, { call, put }: any) {
      console.log(payload);
      let response = {};
      if (payload.talkFunCourseId) {
        response = yield call(packageCoursesByTalkFunCourseId, payload);
      } else {
        response = yield call(queryPackageCourses, payload);
      }

      if (response.code === 0) {
        const { data } = response;
        yield put({
          type: 'redList',
          payload: {
            packageCourse: data,
          },
        });
        return data || true;
      }
      return false;
    },

    // 推广课打开弹框操作
    *packageCourseOpenToast({ payload }: any, { call }: any) {
      const response = yield call(openPopups, payload);
      if (response.code === 0) {
        return true;
      }
      return false;
    },

    // 推广课关闭弹框操作
    *packageCourseCloseToast({ payload }: any, { call }: any) {
      const response = yield call(closePopups, payload);
      if (response.code === 0) {
        return true;
      }
      return false;
    },

    // 获取直播学员数据
    *getLiveStudent({ payload }: any, { call, put }: any) {
      try {
        const response = yield call(queryLiveStudent, payload);
        if (response.code === 0) {
          const { data } = response;
          const { dataList, totalCount } = data;
          yield put({
            type: 'redList',
            payload: {
              liveStudentList: dataList,
              pagination: {
                ...payload,
                total: totalCount,
              },
            },
          });
        }
      } catch (e) {
        yield put({
          type: 'redList',
          payload: {
            liveStudentList: [],
          },
        });
      }
    },

    // 清空直播学员数据
    *clearStudentList({ put }: any) {
      yield put({
        type: 'redList',
        payload: {
          liveStudentList: [],
        },
      });
    },

    // 获取直播学员的课程发言统计列表
    // eslint-disable-next-line consistent-return
    *getLiveStudentChatInfo({ payload }: any, { call }: any) {
      const response = yield call(queryLiveStudentChatInfo, payload);
      if (response.code === 0) {
        const { data } = response;
        return data;
      }
    },

    // 获取回放观看时长/回放观看次数
    // eslint-disable-next-line consistent-return
    *getPlayBackInfo({ payload }: any, { call }: any) {
      const response = yield call(queryPlayBackInfo, payload);
      if (response.code === 0) {
        const { data } = response;
        return data;
      }
    },

    // 获取直播学员的课程详细发言统计列表
    // eslint-disable-next-line consistent-return
    *getLiveStudentChatInfoItem({ payload }: any, { call }: any) {
      const response = yield call(queryLiveStudentChatInfoDetail, payload);
      if (response.code === 0) {
        const { data } = response;
        // yield put({
        //   type: 'redList',
        //   payload: {
        //     liveStudentChatInfoList: data,
        //   },
        // })
        return data;
      }
    },

    // 查询用户评级标准
    // eslint-disable-next-line consistent-return
    *getUserGrade({ payload }: any, { call, put }: any) {
      const response = yield call(queryUserGrade, payload);
      if (response.code === 0) {
        const { data } = response;
        yield put({
          type: 'redList',
          payload: {
            selectTagTypeList: data,
          },
        });
        return data;
      }
    },

    // 导出学员列表
    *exportStudentTable({ payload, dec }: any, { call }: any) {
      try {
        const response = yield call(exportExcelLiveStudent, payload);
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const filename = `投放渠道${date}-${dec}.xls`;
        exportExcel(response.data, filename);
        success('导出成功');
      } catch (e) {
        error('导出失败');
      }
    },

    // 获取一组微信昵称20个
    *GetRandomVistors({ payload }: any, { call }: any) {
      const response = yield call(randomVistors, payload);
      if (response.code === 0) {
        const { data } = response;
        return data;
      }
      return false;
    },

    // 虚拟报名
    *SetVirtualSignUp({ payload }: any, { call }: any) {
      const response = yield call(virtualSignUp, payload);
      if (response.code === 0) {
        const { data } = response;
        return data;
      }
      return false;
    },

    // 批量虚拟报名
    *BatchSetVirtualSignUp({ payload }: any, { call }: any) {
      const response = yield call(batchVirtualSignUp, payload);
      if (response.code === 0) {
        const { data } = response;
        return data;
      }
      return false;
    },
  },

  reducers: {
    redList(state: any, action: { payload: any }) {
      return {
        ...state,
        ...action.payload,
      };
    },
    cleanState(state: any) {
      return {
        ...state,
        liveStudentList: [],
        selectTagTypeList: [],
      };
    },
  },
};
