/*
 * @Descripttion:
 * @Author: Lorder
 * @Date: 2020-03-21 11:42:47
 * @LastEditors: Lorder
 * @LastEditTime: 2020-06-02 10:39:21
 */
import request from '@/utils/request';

// 生成一个虚拟号
export async function createNumber(body: any) {
    return request({ url: 'virtualNumber/getNext', body });
}

// 重新获取
export async function reGetCode(body: any) {
    return request({ url: 'virtualNumber/reGetNext', body });
}

// 查询列表
export async function getList(body: any) {
    return request({ url: 'virtualNumber/virtualNumberList', body });
}

// 获取账号数据
export async function queryWhiteAccount(body: any) {
  return request({ url: 'deleteCustomer/customerList', body });
}
// 绑定记录
export async function customerChangePage(body: any) {
  return request({ url: 'customer/changePage', body });
}
// 获取上传文件列表
export async function queryUploadList(body: any) {
  return request({ url: 'file/page', body });
}

// 提交上传文件信息
export async function setUploadList(body: any) {
  return request({ url: 'file/add', body });
}

// 删除账号
export async function deleteWhiteAccount(body: any) {
  return request({ url: 'deleteCustomer/delete', body });
}

// 清除订单缓存(慎用）
export async function clearOrderCache(body: any) {
  return request({ url: 'customer/clearOrderCache', body })
}

// 获取白名单数据
export async function queryWhiteList(body: any) {
  return request({ url: 'customerWhiteList/getList', body });
}

// 删除白名单数据
export async function deleteWhiteList(body: any) {
  return request({ url: `customerWhiteList/cancel/${body.id}` });
}

// 添加白名单
export async function addWhiteList(body: any) {
  return request({ url: 'customerWhiteList/add', body });
}


// 添加app反馈
export async function getFeedbackList(body: any) {
  return request({ url: 'app/feedback/getFeedbackList', body });
}

// 添加用户投诉
export async function getComplainList(body: any) {
  return request({ url: 'feedback/getFeedbackList', body });
}

// 导出用户投诉数据
export async function exportComplainList(body: any) {
  return request({ url: 'feedback/exportFeedbackList', body });
}

// 生成短链接
export async function enCode(body: any) {
  return request({ url: 'shortUrl/enCode', body });
}

// 还原短链接
export async function deCode(body: any) {
  return request({ url: 'shortUrl/deCode', body });
}

// 退款订单记录
export async function listRefundRecord(body: any) {
    return request({ url: 'order/listRefundRecord', body });
}

// 退款订单记录
export async function changeCourseRecordList(body: any) {
    return request({ url: 'changedata/changeCourseRecordList', body });
}

export async function changeCourseRecordOperatorList(body: any) {
    return request({ url: 'changedata/changeCourseOperatorList', body });
}

// 福利资格列表
export async function queryLiveTicketPage(body: any) {
  return request({ url: 'liveTicket/queryLiveTicketPage', body });
}

// 新增福利配置
export async function addLiveTicket(body: any) {
  return request({ url: 'liveTicket/addLiveTicket', body });
}

// 福利配置编辑回显
export async function getLiveTicketById(body: any) {
  return request({ url: 'liveTicket/getLiveTicketById', body });
}

// 福利配置编辑回显
export async function updateLiveTicket(body: any) {
  return request({ url: 'liveTicket/updateLiveTicket', body });
}

// 删除福利配置
export async function deleteLiveTicket(body: any) {
  return request({ url: 'liveTicket/deleteLiveTicket', body });
}

// 记录处理结果
export async function insertHandleResult(body: any) {
    return request({ url: 'feedback/insertHandleResult', body });
}

// 获取处理状态
export async function handleStatusList(body: any) {
    return request({ url: 'feedback/handleStatusList', body });
}

// 机器人列表
export async function robotExplanList(body: any) {
    return request({ url: 'robot/robotExplanList', body });
}

// 删除一条机器人信息
export async function deleteRobotExplan(body: any) {
    return request({ url: 'robot/deleteRobotExplan', body });
}

// 新建一条机器人信息
export async function createRobotExplan(body: any) {
    return request({ url: 'robot/createRobotExplan', body });
}

// 机器人语音状态列表
export async function voiceStatusList(body: any) {
    return request({ url: 'robot/voiceStatusList', body });
}

// 机器人用户奇偶数
export async function robotNumberTypeList(body: any) {
    return request({ url: 'robot/robotNumberTypeList', body });
}

// 机器人选择用户类型
export async function robotCampDateTypeList(body: any) {
    return request({ url: 'robot/robotCampDateTypeList', body });
}

// 获取语音素材
export async function selectByCode(body: any) {
    return request({ url: 'systemConfig/selectByCode ', body });
}

// 获取指数温度数据
export async function getTemperature(body: any) {
  return request({ url: 'team/queryTemperature', body });
}

// 修改指数温度数据
export async function modifyTemperature(body: any) {
  return request({ url: 'team/modifyTemperature', body });
}

//解绑手机号
export async function deleteCustomer(body: any) {
  return request({ url: 'deleteCustomer/unBind', body });
}

// 获取直播间白名单数据
export async function queryLiveWhiteList(body: any) {
  return request({ url: 'whiteList/queryWhiteListInformation', body });
}

// 删除直播间白名单数据
export async function deleteLiveWhiteList(body: any) {
  return request({ url: 'whiteList/deleteWhiteList', body });
}

// 添加直播间白名单
export async function addLiveWhiteList(body: any) {
  return request({ url: 'whiteList/saveWhiteList', body });
}
// 接口名称：获取用户token
export async function _getUserToken(body: any) {
  return request({ url: 'customer/getUserToken',  params: body,method:'get'});
}

// 获取签名白名单数据
export async function querySignWhiteList(body: any) {
  return request({ url: 'signature/querySignatureWhiteListInformation', body });
}

// 删除签名白名单数据
export async function deleteSignWhiteList(body: any) {
  return request({ url: 'signature/deleteSignatureWhiteList', body });
}

// 添加签名白名单
export async function addSignWhiteList(body: any) {
  return request({ url: 'signature/saveSignatureWhiteList', body });
}

// 获取直播间虚拟机器人任务列表
export async function queryVirtualRobotList(body: any) {
  return request({ url: 'robotTask/list', body });
}

// 获取直播间虚拟机器人详情任务
export async function queryVirtualRobotDetail(body: any) {
  return request({ url: 'robotTask/get', body });
}

// 删除直播间虚拟机器人任务
export async function deleteVirtualRobot(body: any) {
  return request({ url: 'robotTask/updateDeleteStatus', body });
}

// 添加直播间虚拟机器人任务
export async function addVirtualRobot(body: any) {
  return request({ url: 'robotTask/save', body });
}
// 更新直播间虚拟机器人任务
export async function updateVirtualRobot(body: any) {
  return request({ url: 'robotTask/update', body });
}

// 获取直播间虚拟机器人任务列表
export async function queryVirtualRobotChatList(body: any) {
  return request({ url: 'robotChat/list', body });
}

// 获取合同生成列表
export async function queryLawContractList(body: any) {
  return request({ url: 'law/contract/page', body });
}
//生成合同
export async function addLawContract(body: any) {
  return request({ url: 'law/contract/save', body });
}
//黑名单列表
export async function getBlackPage(body: any) {
  return request({ url: 'customer/black/page', body });
}
//添加黑名单
export async function addBlackPage(body: any) {
  return request({ url: 'customer/black/add', body });
}
//移除黑名单
export async function delBlackPage(body: any) {
  return request({ url: `customer/black/delete/${body.id}` });
}
//查看ip用户
export async function lookBlackIpUser(body: any) {
  return request({ url: `customer/black/customer`,body });
}