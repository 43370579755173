/**
 * 后台返回的时间去掉秒
 *
 * @param    {string}  time  时间
 *
 */
const timeToMin = (time: string) => (time ? time.substring(0, 16) : '-');

export default {
  timeToMin,
};
