import request from '@/utils/request';
export async function fakeAccountLogin(params: any) {
  return request({
    url: 'login/agentLogin',
    body: params,
  });
}

export async function getFakeCaptcha(mobile: string) {
  return request(`/api/login/captcha?mobile=${mobile}`);
}

export async function _ddLogin(code: string) {
  return request({ url: `/login/dingtalk/login?code=${code}` });
}
