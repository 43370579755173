import { querytchHelperByClassId, connectTchHelperForClass, querySelectTeacherByClassId, connectTeacherHelperForClass } from '@/services/class';

export default {
  namespace: 'classModule',
  state: {
    pagination: (<any>window).GLOBAL.DEFAULT_PAGINATION, // eslint-disable-line no-undef
    supervisorList: {},
    assistantList: {},
    teacherList: {},
  },

  effects:
    {
      // 根据班级id查询可关联的督导
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,consistent-return
      * supervisorList({ payload }: any, { call, put }: any) {
        const response = yield call(querytchHelperByClassId, payload);
        const { data } = response;
        // const { dataList } = data;
        if (response.code === 0) {
          yield put({
            type: 'redList',
            payload: {
              supervisorList: data,
            },
          })
          return data;
        }
      },
      // 根据班级id查询可关联的助教
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,consistent-return
      * assistantList({ payload }: any, { call, put }: any) {
        const response = yield call(querytchHelperByClassId, payload);
        const { data } = response;
        // const { dataList } = data;
        if (response.code === 0) {
          yield put({
            type: 'redList',
            payload: {
              assistantList: data,
            },
          })
          return data;
        }
      },
      // 根据班级id查询可关联的老师
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,consistent-return
      * teacherList({ payload }: any, { call, put }: any) {
          const response = yield call(querySelectTeacherByClassId, payload);
          const { data } = response;
          // const { dataList } = data;
          yield put({
              type: 'redList',
              payload: {
                  teacherList: {},
              },
          })
          if (response.code === 0) {
              yield put({
                  type: 'redList',
                  payload: {
                      teacherList: data,
                  },
              })
              return data;
          }
      },
      // 班级绑定督导和助教
      // eslint-disable-next-line consistent-return
      * bindTchHelper({ payload }: any, { call, put }: any) {
        const response = yield call(connectTchHelperForClass, payload);
        if (response.code === 0) {
          return true
        }
      },
      // 班级绑定督导和助教
      // eslint-disable-next-line consistent-return
      * bindTeacherHelper({ payload }: any, { call, put }: any) {
          const response = yield call(connectTeacherHelperForClass, payload);
          if (response.code === 0) {
              return true
          }
      },
    },

  reducers: {
    redList(state: any, action: { payload: any; }) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
