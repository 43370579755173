import { getCampList, queryTchHelper, tchHelperEdit, tchHelperAdd, tchHelperStatusUpdate } from '@/services/research';

export default {
  namespace: 'research',
  state: {
    pagination: (<any>window).GLOBAL.DEFAULT_PAGINATION, // eslint-disable-line no-undef
    campList: [],
    supervisorList: [],
  },

  effects:
    {
      // 获取所属营列表
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      * campList({ payload }: any, { call, put }: any) {
        const response = yield call(getCampList, payload);
        const { data } = response;
        if (response.code === 0) {
          yield put({
            type: 'redList',
            payload: {
              campList: data,
            },
          })
        }
      },
      // 获取督导/助教列表数据
      * supervisorList({ payload }: any, { call, put }: any) {
        const response = yield call(queryTchHelper, payload);
        const { data } = response;
        const { dataList, totalCount } = data;
        if (response.code === 0) {
          yield put({
            type: 'redList',
            payload: {
              supervisorList: dataList,
              pagination: {
                ...payload,
                total: totalCount,
              },
            },
          })
        }
      },
      // 新建督导/助教
      * addTchHelper({ payload, parmas }: any, { call, put }: any) {
        const response = yield call(tchHelperAdd, payload);
        if (response.code === 0) {
          yield put({
            type: 'supervisorList',
            payload: parmas,
          });
          return 0;
        } else if (response.code === 9999) {
          return '服务器异常';
        } else {
          return response.message;
        }
      },
      // 编辑督导/助教
      // eslint-disable-next-line consistent-return
      * editTchHelper({ payload, parmas }: any, { call, put }: any) {
        const response = yield call(tchHelperEdit, payload);
        if (response.code === 0) {
          yield put({
            type: 'supervisorList',
            payload: parmas,
          });
          return 0;
        } else if (response.code === 9999) {
          return '服务器异常';
        } else {
          return response.message;
        }
      },
      // 修改督导/助教的状态
      // eslint-disable-next-line consistent-return
      * updateTchHelperStatus({ payload }: any, { call, put }: any) {
        const response = yield call(tchHelperStatusUpdate, payload);
        if (response.code === 0) {
          return true;
        }
        return false;
      },
    },

  reducers: {
    redList(state: any, action: { payload: any; }) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
