import { error, exportExcel, success } from '@/utils/utils';
import moment from 'moment';
import { exportComplainList } from '@/services/tools';

// @ts-ignore
export default {
  namespace: 'operation',
  state: {
    pagination: (<any>window).GLOBAL.DEFAULT_PAGINATION, // eslint-disable-line no-undef
  },

  effects:
    {
      // 导出用户投诉数据
      * exportComplainList({ payload }: any, { call }: any) {
        try {
          const response = yield call(exportComplainList, payload);
          const date = moment().format('YYYY-MM-DD HH:mm:ss')
          const filename = `用户投诉数据${date}.xls`;
          exportExcel(response.data, filename);
          success('导出成功')
        } catch (e) {
          error('导出失败');
        }
      },
    },

  reducers: {
    redList(state: any, action: { payload: any; }) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
