import request from '@/utils/request';

// 获取班级列表
export async function queryClass(body: any) {
  return request({ url: 'class/classList', body });
}

// 获取调班工具日志
export async function queryShiftToolLog(body: any) {
  return request({ url: 'changedata/listOrderChangeLogOfMange', body })
}

// （查询条件）根据归属查询营列表
export async function getCamp(body: any) {
    return request({ url: 'order/getCamp', body });
}

// （查询条件）查询营列表
export async function queryCamp(body: any) {
  return request({ url: 'condition/getCampList', body });
}

// （查询条件）查询期列表
export async function queryStage(body: any) {
  return request({ url: 'condition/getCampDateList', body });
}

// （查询条件）查询老师列表
export async function queryTeacher(body: any) {
  return request({ url: 'condition/getTeacherList', body });
}

// 查询班级成绩数据
export async function queryClassChart(body: any) {
  return request({ url: 'class/classGrade', body });
}

// （查询条件）查询班列表
export async function getClassList(body: any) {
  return request({ url: 'condition/getClassList', body });
}

// 关联群-获取群列表
export async function getQun(body: any) {
    return request({ url: `classSyncWechat/classSyncWechatList?searchName=${body.searchName}`, method: 'POST' });
}

// 关联群-绑定群数据
export async function conQun(body: any) {
    return request({ url: 'classSyncWechat/saveWechatClassRelation', body });
}

// 班级调整-查询学员信息
export async function getCustomerInfo(body: any) {
  return request({ url: 'changedata/getCustomerInfo', body, errToast: false });
}

// 班级调整-查询班级信息
export async function getClassInfo(body: any) {
  return request({ url: 'changedata/getClassInfo', body, errToast: false });
}

// 班级调整-调整单个学员班级
export async function changeUserClass(body: any) {
  return request({ url: 'changedata/changeSingleUserClass', body });
}

// 班级调整-调整整个班级
export async function changeTheClass(body: any) {
  return request({ url: 'changedata/changeWholeClass', body });
}

// 根据班级id查询可关联的督导/助教
export async function querytchHelperByClassId(body: any) {
  return request({ url: 'tchHelper/queryRelate4ClassId', body });
}

// 班级关联督导和助教
export async function connectTchHelperForClass(body: any) {
  return request({ url: '/tchHelper/releateWithClass', method: 'PUT', body })
}

// 根据班级id查询可关联的督导/助教
export async function getStudentAllClass(body: any) {
  return request({ url: 'changedata/getCurrentAllClass', body });
}

// 检查是否可以换ID
export async function checkCourseTransfer(body: any) {
    return request({ url: 'customerCourse/checkCourseTransfer', body })
}

// 更换账户
export async function courseTransfer(body: any) {
    return request({ url: 'customerCourse/courseTransfer', body });
}

// 训练营调班-查询用户班级情况列表
export async function listCustomerClasses(body: any) {
    return request({ url: 'changedata/listCustomerClasses', body })
}

// 调整用户所属期和班
export async function changeCustomerClass(body: any) {
    return request({ url: 'changedata/changeCustomerClass', body });
}

// 检查班级是否可选
export async function checkClassSelect(body: any) {
    return request({ url: 'class/checkClassSelect', body });
}

// 根据班级id查询可关联的教师 POST /api/v1/tchHelper/selectTeacher
export async function querySelectTeacherByClassId(body: any) {
    return request({ url: 'tchHelper/selectTeacher', body });
}

// 班级关联教师 POST /api/v1/tchHelper/updateClassTeacher
export async function connectTeacherHelperForClass(body: any) {
    return request({ url: 'tchHelper/updateClassTeacher', body })
}

// 根据手机号获取相关信息 POST /api/v1/class/getOrderedCourseByMobile
export async function getOrderedCourseByMobile(body: any) {
    return request({ url: 'class/getOrderedCourseByMobile', body })
}

// 获取赠送课程
export async function getGiveCourseInfo(code: any) {
    return request({url: `order/getGiveCourseInfo/${code}` });
}

// 老师搜索下拉框
export async function querFuzzySearchTeacher(body: any) {
  return request({ url: 'teacher/listTeacher', body });
}