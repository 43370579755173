// 因接口跨域，故无法拿到错误状态码
// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };
import md5 from 'js-md5';
import {
  Base64,
} from 'js-base64';
import router from 'umi/router';
import {
  notification,
} from 'antd';
import axios from 'axios';
import { InstanceChainMap } from 'lodash-decorators';

const isTest = APP_ENV !== 'prod'; // eslint-disable-line no-undef
const baseUrl = BASE_API; // eslint-disable-line no-undef
const salt = '';

class HttpRequest {
  constructor() {
    this.options = {
      method: 'post',
      url: '',
      data: {},
      errToast: true,
    };
    this.token = localStorage.getItem('token') || '';
    this.checkToken();
  }

  checkToken() {
    // 如果没有token、中断请求并跳转到对应的登录页
    const pathname = ['/login', '/ddlogin'];
    const thisPathname = window.location.pathname;
    // 判断用户身份跳转对应登录页
    const loginPathName = window.localStorage.userType === "agent" ? "/login" : "/DDLogin";
    if (this.token === '' && !pathname.includes(thisPathname.toLocaleLowerCase())) {
      return new Promise((resolve, reject) => {
        reject(
          router.replace({
            pathname: loginPathName,
            query: {
              redirect: thisPathname,
            },
          }),
        );
      });
    }
    return '';
  }

  // 签名
  sign(body, timestamp) {
    const str = Object.keys(body)
      .sort()
      .reduce((sum, next) => sum + next + body[next], '')
      .replace(/\s/g, '');
    const sign = md5(Base64.encode(String(timestamp)) + this.token + salt + str);
    return sign;
  }

  consoleErr(res) {
    if (!isTest) return;
    // 控制台打印报错信息
    const body = {
      ...this.options.body,
      timestamp: this.options.timestamp,
    };
    console.error('请求URL: ', baseUrl + this.options.url);
    console.error('请求参数：');
    console.error(JSON.stringify(body, null, 2));
    console.error('接口返回：');
    console.error(JSON.stringify(res, null, 2));
  }

  handleResponse(res) {
    // token失效
    if (res.code === 10004) {
      notification.error({
        message: '登录已过期，请重新登录',
      });
    // 判断用户身份跳转对应登录页
    const loginPathName = window.localStorage.userType === "agent" ? "/login" : "/DDLogin";
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      return Promise.reject(
        router.replace({
          pathname: loginPathName,
          query: {
            redirect: window.location.pathname,
          },
        }),
      );
    }
    if (res.code !== 0) {
      if (res && this.options.errToast) {
        window.setTimeout(
          notification.error({
            message: res.message,
            description: res.exception,
          }),
          0,
        );
        this.consoleErr(res, this.options);
        return Promise.reject(res)
      }
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  }

  // 由于后端不处理undefined,因此前端在发送请求的时候，过滤掉值为undefined或者字符串'undefined'的字段
  filterUndefined(config) {
    if (Object.prototype.toString.call(config) === '[object Object]') {
      Object.keys(config).forEach(i => {
          if (config[i] === 'undefined' || config[i] === undefined) {
              delete config[i];
          }
          // 这里后端只校验一层，因此不需要递归了
          // this.filterUndefined(config[i])
      })
    }
  }

  // 请求拦截
  interceptors(instance) {
    instance.interceptors.request.use(
      config => {
        if ('data' in config && (config.data === 'undefined' || config.data === undefined)) {
            delete config.data
        } else {
            this.filterUndefined(config.data)
        }
        return config
      }, error => {
        console.error('请求超时了,请刷新页面重试')
        return Promise.reject(error)
      },
    )
    // 添加响应拦截器
    instance.interceptors.response.use(
      res => {
        if (res.headers['content-type'] && res.headers['content-type'].match('excel')) {
          return Promise.resolve(res);
        }
        const {
          data,
        } = res;
        if (res.request.responseType == "arraybuffer" && res.config.url == 'putInChannel/exportData') {
          let enc = new TextDecoder('utf-8')
          const data1 = JSON.parse(enc.decode(new Uint8Array(res.data)))
          return Promise.reject(data1)
        }

        return this.handleResponse(data);
      },
      error => Promise.reject(error),
    );
  }

  // 创建实例
  create() {
    const timestamp = ~~(+new Date() / 1000); // eslint-disable-line no-bitwise
    const conf = {
      baseURL: baseUrl,
      timeout: this.options.timeout || 60000,
      ...this.options.data.config,
      headers: {
        timestamp,
        signature: this.sign(this.options.data, timestamp),
        token: this.token,
      },
    };
    // 上传 multipart/form-data 类型数据
    if (this.options.type === 'form-data') {
      conf.headers['Content-Type'] = 'multipart/form-data';
    }
    return axios.create(conf);
  }

  // 请求实例
  request(options) {
    // 合并请求参数
    this.options = {
      ...this.options,
      ...options,
      data: {
        ...this.options.data,
        ...options.body,
      },
    };

    // FormData类型不支持解构
    if (options.type === 'form-data') {
      this.options.data = options.body;
    }

    // 不需要解构
    if (options.type === 'normal') {
      this.options.data = options.body;
    }

    const instance = this.create();
    this.interceptors(instance, options.url);
    return instance(this.options);
  }
}

function http(obj) {
  //   const vm = this;

  return new HttpRequest().request(obj);
}

export default http;
