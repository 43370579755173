import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';
import { routerRedux } from 'dva/router';
import { fakeAccountLogin, getFakeCaptcha } from '../services/login';
import { getPageQuery, setLoginInfo } from '../utils/utils';

export interface StateType {
  status?: 'ok' | 'error';
  userInfo?: object;
  currentAuthority?: 'user' | 'guest' | 'admin';
}

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T },
) => void;

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    logout: Effect;
    checkStatus: Effect;
    getCaptcha: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'login',

  state: {
    userInfo: {},
  },

  effects: {
    *login({ payload }, { call, put }) {
      let response = { status: '', code: null };
      try {
        response = yield call(fakeAccountLogin, payload);
        response.status = response.code === 0 ? 'ok' : 'error';
      } catch (error) {
        response.status = 'error';
      }
      yield put({
        type: 'changeLoginStatus',
        payload: response,
      });

      // Login successfully
      if (response.status === 'ok') {
        // const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        const { redirect } = params as { redirect: string };
        // if (redirect) {
        //   const redirectUrlParams = new URL(redirect);
        //   if (redirectUrlParams.origin === urlParams.origin) {
        //     redirect = redirect.substr(urlParams.origin.length);
        //     if (redirect.match(/^\/.*#/)) {
        //       redirect = redirect.substr(redirect.indexOf('#') + 1);
        //     }
        //   } else {
        //     window.location.href = redirect;
        //     return;
        //   }
        // }
        // 设置的登录用户类型为 代理商
        window.localStorage.userType = "agent"
        yield put(routerRedux.replace(redirect || '/'));
      }
    },

    *logout({ payload }, { call, put }) {
      localStorage.removeItem('token');
      localStorage.removeItem('menu');
      // 判断用户身份跳转对应登录页
      const loginPathName = window.localStorage.userType === "agent" ? "/login" : "/DDLogin";
      yield put(routerRedux.push(loginPathName));
    },

    *checkStatus({ payload }, { call, put }) {
      const token = localStorage.getItem('token');
      if (!token) {
        // 判断用户身份跳转对应登录页
        const loginPathName = window.localStorage.userType === "agent" ? "/login" : "/DDLogin";
        yield put(routerRedux.push(loginPathName));
      }
    },


    *getCaptcha({ payload }, { call }) {
      yield call(getFakeCaptcha, payload);
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      if (payload.status === 'ok') setLoginInfo(payload.data);
      return {
        ...state,
        userInfo: payload.data,
      };
    },
  },
};

export default Model;
