/*
 * @Author: blacker
 * @Created: 2019-08-05 22:43:37
 * @LastEditTime: 2019-08-12 14:28:25
 */
export default {
  'menu.fission.cash': 'Cash Info',
  'menu.fission.invite': 'Invite Info',
  'menu.fission': 'Fission',
  'menu.holiday': 'Holiday',
  'menu.course.expend_link': 'Expend Link',
  'menu.course.build_tool': 'Build Tool',
  'menu.course.course_list': 'Course List',
  'menu.class.class_chart': 'Class Chart',
  'menu.student': 'Student',
  'menu.audio': 'Audio',
  'menu.consultant': 'Consultant',
  'menu.consultant.manage': 'Manage',
  'menu.consultant.members': 'Members',
  'menu.order': 'Order',
  'menu.setting': 'Setting',
  'menu.lesson': 'Lesson',
  'menu.homework': 'Homework',
  'menu.question': 'Question',
  'menu.class.adjust': 'Adjust',
  'menu.class.detail': 'Detail',
  'menu.class': 'Class',
  'menu.welcome': 'Customer',
  'menu.upload': 'Upload File',
  'menu.course': 'Course',
  'menu.course.send': 'Send Course',
  'menu.course_add_edit': 'Operate',
  'menu.course_manage': 'Manage',
  'menu.camp': 'Camp',
  'menu.customer': 'Customer',
  'menu.teacher': 'Teacher',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.account.password': 'Password',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.throw': 'Throw',
  'menu.throw.manage': 'Manage',
  'menu.throw.pagemembers': 'Pagemanage',
  'menu.throw.channel': 'Channel',
  'menu.link': 'Link Manage',
    'menu.tuan': 'Tuan',
    'menu.tuan.list': 'Tuan List',
    'menu.tuan.order': 'Tuan Order',
};
