import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/www/.jenkins/workspace/sns-admin-h5/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    redirect: '/app/block',
    exact: true,
  },
  {
    path: '/app',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__RouteFilter" */ '../../layouts/RouteFilter'),
          LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/RouteFilter').default,
    Routes: [require('../Authorized').default],
    authority: ['admin', 'user'],
    routes: [
      {
        path: '/app',
        redirect: '/app/customer',
        exact: true,
      },
      {
        path: '/app/admin',
        name: 'admin',
        icon: 'user',
        routes: [
          {
            path: '/app/admin/menu',
            name: 'menu',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__admin__menu" */ '../admin/menu'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/menu').default,
            exact: true,
          },
          {
            path: '/app/admin/role',
            name: 'role',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__admin__role" */ '../admin/role'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/role').default,
            exact: true,
          },
          {
            path: '/app/admin/role/add',
            name: 'role-add',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__admin__role-add-edit" */ '../admin/role-add-edit'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/role-add-edit').default,
            exact: true,
          },
          {
            path: '/app/admin/user',
            name: 'user',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__admin__user" */ '../admin/user'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../admin/user').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/customer',
        name: 'customer',
        icon: 'user',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__customer__customer" */ '../customer/customer'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../customer/customer').default,
        exact: true,
      },
      {
        path: '/app/openclass',
        name: 'openclass',
        icon: 'user',
        routes: [
          {
            path: '/app/openclass/class',
            name: 'class',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__openClass__class" */ '../openClass/class'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../openClass/class').default,
            exact: true,
          },
          {
            path: '/app/openclass/period',
            name: 'period',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__openClass__livePeriod" */ '../openClass/livePeriod'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../openClass/livePeriod').default,
            exact: true,
          },
          {
            path: '/app/openclass/statistics',
            name: 'statistics',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__openClass__Statistics" */ '../openClass/Statistics'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../openClass/Statistics').default,
            exact: true,
          },
          {
            path: '/app/openclass/videoUpload',
            name: 'videoUpload',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__openClass__videoUpload__videoUpload" */ '../openClass/videoUpload/videoUpload'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../openClass/videoUpload/videoUpload').default,
            exact: true,
          },
          {
            path: '/app/openclass/course',
            name: 'course',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__openClass__Course" */ '../openClass/Course'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../openClass/Course').default,
            exact: true,
          },
          {
            path: '/app/openclass/periodBeforeSetting',
            name: 'periodBeforeSetting',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__openClass__periodBeforeSetting" */ '../openClass/periodBeforeSetting'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../openClass/periodBeforeSetting').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/project',
        name: 'sku',
        icon: 'user',
        routes: [
          {
            path: '/app/project/sku',
            name: 'sku',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__project__sku" */ '../project/sku'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../project/sku').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/customer',
        name: 'sku',
        icon: 'user',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__project__sku" */ '../project/sku'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../project/sku').default,
        exact: true,
      },
      {
        path: '/app/research',
        name: 'research',
        icon: 'smile',
        routes: [
          {
            path: '/app/research/teacher',
            name: 'teacher',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__research__teacher" */ '../research/teacher'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../research/teacher').default,
            exact: true,
          },
          {
            path: '/app/research/supervisor',
            name: 'supervisor',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__research__supervisor" */ '../research/supervisor'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../research/supervisor').default,
            exact: true,
          },
          {
            path: '/app/research/assistant',
            name: 'assistant',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__research__assistant" */ '../research/assistant'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../research/assistant').default,
            exact: true,
          },
          {
            path: '/app/research/lecturer',
            name: 'lecturer',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__research__lecturer" */ '../research/lecturer'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../research/lecturer').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/setting',
        name: 'setting',
        icon: 'setting',
        routes: [
          {
            path: '/app/setting/setting',
            name: 'setting',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__setting__setting" */ '../setting/setting'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../setting/setting').default,
            exact: true,
          },
          {
            path: '/app/setting/planassociated',
            name: 'planassociated',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__setting__planassociated" */ '../setting/planassociated'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../setting/planassociated').default,
            exact: true,
          },
          {
            path: '/app/setting/liveService',
            name: 'setting',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__setting__liveService__liveService" */ '../setting/liveService/liveService'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../setting/liveService/liveService').default,
            exact: true,
          },
          {
            path: '/app/setting/mengYue',
            name: 'setting',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__setting__mengYue__index" */ '../setting/mengYue/index'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../setting/mengYue/index').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/order',
        name: 'order',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__order__order-new" */ '../order/order-new'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../order/order-new').default,
        exact: true,
      },
      {
        path: '/app/refund-log',
        name: 'refundLog',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__refundLog-new" */ '../tools/refundLog-new'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/refundLog-new').default,
        exact: true,
      },
      {
        path: '/app/userToken',
        name: 'userToken',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__userToken" */ '../tools/userToken'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/userToken').default,
        exact: true,
      },
      {
        path: '/app/pdfH5',
        name: 'pdfH5',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__pdfH5" */ '../tools/pdfH5'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/pdfH5').default,
        exact: true,
      },
      {
        path: '/app/law/contract',
        name: 'lawContract',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__lawContract" */ '../tools/lawContract'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/lawContract').default,
        exact: true,
      },
      {
        path: '/app/payType/manage',
        name: 'payTypeManage',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__payTypeManage" */ '../tools/payTypeManage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/payTypeManage').default,
        exact: true,
      },
      {
        path: '/app/openClassIndent',
        name: 'openClassIndent',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__openClassOrder__OpenClass" */ '../openClassOrder/OpenClass'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../openClassOrder/OpenClass').default,
        exact: true,
      },
      {
        path: '/app/openClassRefundRecord',
        name: 'openClassRefundRecord',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__openClassRefund__open-class-refund" */ '../openClassRefund/open-class-refund'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../openClassRefund/open-class-refund').default,
        exact: true,
      },
      {
        path: '/app/consultOrder',
        name: 'consultOrder',
        icon: 'hdd',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__order__consultOrder__consult-order" */ '../order/consultOrder/consult-order'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../order/consultOrder/consult-order').default,
        exact: true,
      },
      {
        path: '/app/class',
        name: 'class',
        icon: 'home',
        routes: [
          {
            path: '/app/class/detail',
            name: 'detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__class__class" */ '../class/class'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../class/class').default,
            exact: true,
          },
          {
            path: '/app/class/chart/:id',
            name: 'class_chart',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__class__chart" */ '../class/chart'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../class/chart').default,
            exact: true,
          },
          {
            path: '/app/class/change',
            name: 'change',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__class__classChange" */ '../class/classChange'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../class/classChange').default,
            exact: true,
          },
          {
            path: '/app/class/move',
            name: 'move',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__class__classMove" */ '../class/classMove'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../class/classMove').default,
            exact: true,
          },
          {
            path: '/app/class/log',
            name: 'log',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__class__classLog" */ '../class/classLog'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../class/classLog').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/student',
        name: 'student',
        icon: 'read',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__student__student" */ '../student/student'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../student/student').default,
        exact: true,
      },
      {
        path: '/app/question',
        name: 'question',
        icon: 'calculator',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__question__question" */ '../question/question'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../question/question').default,
        exact: true,
      },
      {
        path: '/app/homework',
        name: 'homework',
        icon: 'copy',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__homework__homework" */ '../homework/homework'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../homework/homework').default,
        exact: true,
      },
      {
        path: '/app/lesson',
        name: 'lesson',
        icon: 'tags',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__lesson__lesson" */ '../lesson/lesson'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../lesson/lesson').default,
        exact: true,
      },
      {
        path: '/app/liveLesson',
        name: 'liveLesson',
        icon: 'tags',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__liveLesson__liveLesson" */ '../liveLesson/liveLesson'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../liveLesson/liveLesson').default,
        exact: true,
      },
      {
        path: '/app/course/course-add-edit/:operate',
        name: 'course_add_edit',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__course__child__course-add-edit" */ '../course/child/course-add-edit'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../course/child/course-add-edit').default,
        exact: true,
      },
      {
        path: '/app/course/course-manage/:id/:type',
        name: 'course_manage',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__course__child__course-manage" */ '../course/child/course-manage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../course/child/course-manage').default,
        exact: true,
      },
      {
        path: '/app/course',
        name: 'course',
        icon: 'fire',
        routes: [
          {
            path: '/app/course/course-list',
            name: 'course_list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__course__course" */ '../course/course'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../course/course').default,
            exact: true,
          },
          {
            path: '/app/course/build-tool',
            name: 'build_tool',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__course__build-tool" */ '../course/build-tool'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../course/build-tool').default,
            exact: true,
          },
          {
            path: '/app/course/expend-link',
            name: 'expend_link',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__course__expend-link" */ '../course/expend-link'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../course/expend-link').default,
            exact: true,
          },
          {
            path: '/app/course/send',
            name: 'send',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__course__send-course" */ '../course/send-course'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../course/send-course').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/camp',
        name: 'camp',
        icon: 'calendar',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__camp__camp" */ '../camp/camp'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../camp/camp').default,
        exact: true,
      },
      {
        path: '/app/consultant',
        name: 'consultant',
        icon: 'rise',
        routes: [
          {
            path: '/app/consultant/manage',
            name: 'manage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__consultant__manage__manage" */ '../consultant/manage/manage'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../consultant/manage/manage').default,
            exact: true,
          },
          {
            path: '/app/consultant/members',
            name: 'members',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__consultant__members__members" */ '../consultant/members/members'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../consultant/members/members').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/audio',
        name: 'audio',
        icon: 'audio',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__audio__audio" */ '../audio/audio'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../audio/audio').default,
        exact: true,
      },
      {
        path: '/app/examination/examination',
        name: 'examination',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__examination__examination" */ '../examination/examination'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../examination/examination').default,
        exact: true,
      },
      {
        path: '/app/examination/examinee',
        name: 'examinee',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__examination__examinee" */ '../examination/examinee'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../examination/examinee').default,
        exact: true,
      },
      {
        path: '/app/examination/paper',
        name: 'paper',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__examination__paper" */ '../examination/paper'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../examination/paper').default,
        exact: true,
      },
      {
        path: '/app/examination/topics',
        name: 'topics',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__examination__topics" */ '../examination/topics'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../examination/topics').default,
        exact: true,
      },
      {
        path: '/app/setting',
        name: 'setting',
        icon: 'setting',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__setting__setting" */ '../setting/setting'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../setting/setting').default,
        exact: true,
      },
      {
        path: '/app/upload',
        name: 'upload',
        icon: 'upload',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__upload-center__upload" */ '../upload-center/upload'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../upload-center/upload').default,
        exact: true,
      },
      {
        path: '/app/throw',
        name: 'throw',
        icon: 'project',
        routes: [
          {
            path: '/app/throw/channel',
            name: 'channel',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__channel__channel" */ '../throw/channel/channel'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/channel/channel').default,
            exact: true,
          },
          {
            path: '/app/throw/pagemanage',
            name: 'pagemanage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__pagemanage__manage" */ '../throw/pagemanage/manage'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/pagemanage/manage').default,
            exact: true,
          },
          {
            path: '/app/throw/manage',
            name: 'manage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__manage__manage" */ '../throw/manage/manage'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/manage/manage').default,
            exact: true,
          },
          {
            path: '/app/throw/platform/manage',
            name: 'platform',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__platform__index" */ '../throw/platform/index'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/platform/index').default,
            exact: true,
          },
          {
            path: '/app/throw/platform/manage/manageConfig',
            name: 'manageConfig',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__platform__ManageConfig" */ '../throw/platform/ManageConfig'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/platform/ManageConfig').default,
            exact: true,
          },
          {
            path: '/app/throw/statistics',
            name: 'statistics',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__statistics__statistics" */ '../throw/statistics/statistics'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/statistics/statistics').default,
            exact: true,
          },
          {
            path: '/app/throw/robotQuestionnaire',
            name: 'robotQuestionnaire',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__robotQuestionnaire__robotQuestionnaire" */ '../throw/robotQuestionnaire/robotQuestionnaire'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/robotQuestionnaire/robotQuestionnaire')
                  .default,
            exact: true,
          },
          {
            path: '/app/throw/robotQuestionnaire/question',
            name: 'question',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__robotQuestionnaire__questionPage" */ '../throw/robotQuestionnaire/questionPage'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/robotQuestionnaire/questionPage').default,
            exact: true,
          },
          {
            path: '/app/throw/robotQuestionnaire/questionDetail',
            name: 'question',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__robotQuestionnaire__questionDetail" */ '../throw/robotQuestionnaire/questionDetail'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/robotQuestionnaire/questionDetail').default,
            exact: true,
          },
          {
            path: '/app/throw/preset/question',
            name: 'presetQuestion',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__presetQuestion__presetQuestion" */ '../throw/presetQuestion/presetQuestion'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/presetQuestion/presetQuestion').default,
            exact: true,
          },
          {
            path: '/app/throw/beyouBaidu/page',
            name: 'beyouBaiduPage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__throw__beyouBaidu__beyouBaiduPage" */ '../throw/beyouBaidu/beyouBaiduPage'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../throw/beyouBaidu/beyouBaiduPage').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/coupon',
        name: 'coupon',
        icon: 'red-envelope',
        routes: [
          {
            path: '/app/coupon/list',
            name: 'coupon',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__coupon__coupon__coupon" */ '../coupon/coupon/coupon'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../coupon/coupon/coupon').default,
            exact: true,
          },
          {
            path: '/app/coupon/record',
            name: 'record',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__coupon__coupon__couponRecord" */ '../coupon/coupon/couponRecord'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../coupon/coupon/couponRecord').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/holiday',
        name: 'holiday',
        icon: 'carry-out',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__holiday__holiday" */ '../holiday/holiday'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../holiday/holiday').default,
        exact: true,
      },
      {
        path: '/app/fission',
        name: 'fission',
        icon: 'deployment-unit',
        routes: [
          {
            path: '/app/fission/invite',
            name: 'invite',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__fission__invite" */ '../fission/invite'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../fission/invite').default,
            exact: true,
          },
          {
            path: '/app/fission/advanced-invite',
            name: 'advanced-invite',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__fission__advanced-invite" */ '../fission/advanced-invite'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../fission/advanced-invite').default,
            exact: true,
          },
          {
            path: '/app/fission/cash',
            name: 'cash',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__fission__cash" */ '../fission/cash'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../fission/cash').default,
            exact: true,
          },
          {
            path: '/app/fission/little-statistics',
            name: 'statistics',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__fission__little-white-statistics" */ '../fission/little-white-statistics'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../fission/little-white-statistics').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/link',
        name: 'link',
        icon: 'link',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__throw__link__link" */ '../throw/link/link'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../throw/link/link').default,
        exact: true,
      },
      {
        path: '/app/live-data',
        name: 'live',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live" */ '../live/live'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live').default,
        exact: true,
      },
      {
        path: '/app/live-term',
        name: 'live-term',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__term" */ '../live/term'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/term').default,
        exact: true,
      },
      {
        path: '/app/live-course-group',
        name: 'live-course-group',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__course-group" */ '../live/course-group'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/course-group').default,
        exact: true,
      },
      {
        path: '/app/live-inter',
        name: 'live-inter',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live-inter" */ '../live/live-inter'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live-inter').default,
        exact: true,
      },
      {
        path: '/app/live-student',
        name: 'live-student',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live-student" */ '../live/live-student'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live-student').default,
        exact: true,
      },
      {
        path: '/app/live-order',
        name: 'live-order',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live-order" */ '../live/live-order'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live-order').default,
        exact: true,
      },
      {
        path: '/app/live-link',
        name: 'live-link',
        icon: 'live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__link" */ '../live/link'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/link').default,
        exact: true,
      },
      {
        path: '/app/live-member',
        name: 'live-member',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__member" */ '../live/member'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/member').default,
        exact: true,
      },
      {
        path: '/app/live-class',
        name: 'live-class',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live-class" */ '../live/live-class'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live-class').default,
        exact: true,
      },
      {
        path: '/app/live-student',
        name: 'live-student',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live-student" */ '../live/live-student'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live-student').default,
        exact: true,
      },
      {
        path: '/app/live-class-detail',
        name: 'live-class-detail',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__live-class-detail" */ '../live/live-class-detail'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/live-class-detail').default,
        exact: true,
      },
      {
        path: '/app/live-keys',
        name: 'live-keys',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__keys" */ '../live/keys'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/keys').default,
        exact: true,
      },
      {
        path: '/app/playback',
        name: 'playback',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__playback" */ '../live/playback'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/playback').default,
        exact: true,
      },
      {
        path: '/app/chat',
        name: 'chat',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live__chat" */ '../live/chat'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../live/chat').default,
        exact: true,
      },
      {
        path: '/app/insurance',
        name: 'insurance',
        icon: 'smile',
        routes: [
          {
            path: '/app/insurance/list',
            name: 'list',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__insurance__list" */ '../insurance/list'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../insurance/list').default,
            exact: true,
          },
          {
            path: '/app/insurance/order',
            name: 'order',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__insurance__index" */ '../insurance/index'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../insurance/index').default,
            exact: true,
          },
          {
            path: '/app/insurance/work',
            name: 'work',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__insurance__work" */ '../insurance/work'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../insurance/work').default,
            exact: true,
          },
          {
            path: '/app/insurance/camp',
            name: 'camp',
            icon: 'smile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__insurance__selectCamp" */ '../insurance/selectCamp'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../insurance/selectCamp').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/anchor',
        name: 'anchor',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__anchor__anchor" */ '../anchor/anchor'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../anchor/anchor').default,
        exact: true,
      },
      {
        path: '/app/anchor-detail',
        name: 'anchor-detail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__anchor__anchor-detail" */ '../anchor/anchor-detail'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../anchor/anchor-detail').default,
        exact: true,
      },
      {
        path: '/app/password',
        name: 'password',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__login__password" */ '../login/password'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../login/password').default,
        exact: true,
      },
      {
        path: '/app/block',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__block" */ '../block'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../block').default,
        exact: true,
      },
      {
        path: '/app/market',
        name: 'market',
        icon: 'fire',
        routes: [
          {
            path: '/app/market/market-workers',
            name: 'market-workers',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__market__market-workers" */ '../market/market-workers'),
                  LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                    .default,
                })
              : require('../market/market-workers').default,
            exact: true,
          },
        ],
      },
      {
        path: '/app/department-config',
        name: 'department-config',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__market__DepartmentConfig" */ '../market/DepartmentConfig'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../market/DepartmentConfig').default,
        exact: true,
      },
      {
        path: '/app/order-change',
        name: 'order-change',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__index" */ '../tools/index'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/index').default,
        exact: true,
      },
      {
        path: '/app/get-verification-code',
        name: 'obtain',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__GetVerificationCode" */ '../tools/GetVerificationCode'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/GetVerificationCode').default,
        exact: true,
      },
      {
        path: '/app/order-change-log',
        name: 'order-change',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__orderChangeLog" */ '../tools/orderChangeLog'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/orderChangeLog').default,
        exact: true,
      },
      {
        path: '/app/virtual-phone',
        name: 'virtual-phone',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__virtualPhone" */ '../tools/virtualPhone'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/virtualPhone').default,
        exact: true,
      },
      {
        path: '/app/virtual-robot',
        name: 'virtual-robot',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__virtualRobot" */ '../tools/virtualRobot'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/virtualRobot').default,
        exact: true,
      },
      {
        path: '/app/virtual-robot/virtual-robot-add-edit/:operate',
        name: 'virtual_robot_add_edit',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__child__virtual-robot-add-edit" */ '../tools/child/virtual-robot-add-edit'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/child/virtual-robot-add-edit').default,
        exact: true,
      },
      {
        path: '/app/white-account',
        name: 'white-account',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__whiteAccount" */ '../tools/whiteAccount'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/whiteAccount').default,
        exact: true,
      },
      {
        path: '/app/white-list',
        name: 'white-list',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__whiteList" */ '../tools/whiteList'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/whiteList').default,
        exact: true,
      },
      {
        path: '/app/live-white',
        name: 'live-white',
        icon: 'tool',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__liveWhite" */ '../tools/liveWhite'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/liveWhite').default,
        exact: true,
      },
      {
        path: '/app/sign-white',
        name: 'sign-white',
        icon: 'tool',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__signWhite" */ '../tools/signWhite'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/signWhite').default,
        exact: true,
      },
      {
        path: '/app/black-list',
        name: 'black-list',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__blackList" */ '../tools/blackList'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/blackList').default,
        exact: true,
      },
      {
        path: '/app/app-research',
        name: 'app-research',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__appResearch" */ '../tools/appResearch'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/appResearch').default,
        exact: true,
      },
      {
        path: '/app/app-complain',
        name: 'app-complain',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__appComplain" */ '../tools/appComplain'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/appComplain').default,
        exact: true,
      },
      {
        path: '/app/miniProgram',
        name: 'miniProgram',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__miniProgram" */ '../operation/miniProgram'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/miniProgram').default,
        exact: true,
      },
      {
        path: '/app/configuration',
        name: 'configuration',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__configuration" */ '../operation/configuration'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/configuration').default,
        exact: true,
      },
      {
        path: '/app/addArticle',
        name: 'addArticle',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__miniProgram__components__addArticle" */ '../operation/miniProgram/components/addArticle'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/miniProgram/components/addArticle').default,
        exact: true,
      },
      {
        path: '/app/activeConfig',
        name: 'activeConfig',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__activeConfig" */ '../operation/activeConfig'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/activeConfig').default,
        exact: true,
      },
      {
        path: '/app/trainingCamp',
        name: 'trainingCamp',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__trainingCamp" */ '../operation/trainingCamp'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/trainingCamp').default,
        exact: true,
      },
      {
        path: '/app/trainingStudentDetails',
        name: 'trainingStudentDetails',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__studentDetails" */ '../operation/studentDetails'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/studentDetails').default,
        exact: true,
      },
      {
        path: '/app/checkInList',
        name: 'checkInList',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__checkInList" */ '../operation/checkInList'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/checkInList').default,
        exact: true,
      },
      {
        path: '/app/checkInConfig',
        name: 'checkInConfig',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__checkInConfig" */ '../operation/checkInConfig'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/checkInConfig').default,
        exact: true,
      },
      {
        path: '/app/short-url',
        name: 'short-url',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__shortUrl" */ '../tools/shortUrl'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/shortUrl').default,
        exact: true,
      },
      {
        path: '/app/Refund-risk',
        name: 'Refund-risk',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__RefundRisk" */ '../tools/RefundRisk'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/RefundRisk').default,
        exact: true,
      },
      {
        path: '/app/RefundRisk',
        name: 'RefundRisk',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__RefundRisk" */ '../tools/RefundRisk'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/RefundRisk').default,
        exact: true,
      },
      {
        path: '/app/Exponential',
        name: 'Exponential',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__Exponential" */ '../tools/Exponential'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/Exponential').default,
        exact: true,
      },
      {
        path: '/app/tools/ai',
        name: 'ai',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tools__ai" */ '../tools/ai'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tools/ai').default,
        exact: true,
      },
      {
        path: '/app/counselor/list',
        name: 'counselor',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__counselor__counselor" */ '../counselor/counselor'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../counselor/counselor').default,
        exact: true,
      },
      {
        path: '/app/counselor/counselor-add-edit/:operate',
        name: 'counselor-add-edit',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__counselor__components__counselor-add-edit" */ '../counselor/components/counselor-add-edit'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../counselor/components/counselor-add-edit').default,
        exact: true,
      },
      {
        path: '/app/consult/configuration',
        name: 'configuration',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__counselor__consultConfiguration" */ '../counselor/consultConfiguration'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../counselor/consultConfiguration').default,
        exact: true,
      },
      {
        path: '/app/counselor/evaluate',
        name: 'evaluate',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__counselor__evaluate" */ '../counselor/evaluate'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../counselor/evaluate').default,
        exact: true,
      },
      {
        path: '/app/goods/configuration',
        name: 'configuration',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__goods__goodsConfiguration" */ '../goods/goodsConfiguration'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../goods/goodsConfiguration').default,
        exact: true,
      },
      {
        path: '/app/goods/type',
        name: 'goodsType',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__goods__goodsType" */ '../goods/goodsType'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../goods/goodsType').default,
        exact: true,
      },
      {
        path: '/app/shipments/list',
        name: 'shipmentsList',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__shipments__shipmentsList" */ '../shipments/shipmentsList'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../shipments/shipmentsList').default,
        exact: true,
      },
      {
        path: '/app/shipments/shipmentsInfo',
        name: 'shipmentsInfo',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__shipments__shipmentsInfo" */ '../shipments/shipmentsInfo'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../shipments/shipmentsInfo').default,
        exact: true,
      },
      {
        path: '/app/returns/list',
        name: 'returnsList',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__shipments__returnsList" */ '../shipments/returnsList'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../shipments/returnsList').default,
        exact: true,
      },
      {
        path: '/app/logistics/page',
        name: 'logisticsPage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__logistics__index" */ '../logistics/index'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../logistics/index').default,
        exact: true,
      },
      {
        path: '/app/multi/send',
        name: 'multiSend',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__logistics__multiSend" */ '../logistics/multiSend'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../logistics/multiSend').default,
        exact: true,
      },
      {
        path: '/app/invoice/export',
        name: 'invoiceExport',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__logistics__invoiceExport" */ '../logistics/invoiceExport'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../logistics/invoiceExport').default,
        exact: true,
      },
      {
        path: '/app/tencentChannel/tencentFilter',
        name: 'tencentFilter',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__tencentChannel__tencentFilter" */ '../tencentChannel/tencentFilter'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../tencentChannel/tencentFilter').default,
        exact: true,
      },
      {
        path: '/app/eyesight/page',
        name: 'eyesightPage',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__eyesight__childPage" */ '../eyesight/childPage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../eyesight/childPage').default,
        exact: true,
      },
      {
        path: '/app/optometry/list',
        name: 'optometryList',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__eyesight__optometryPage" */ '../eyesight/optometryPage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../eyesight/optometryPage').default,
        exact: true,
      },
      {
        path: '/app/eyesight-record/list/:operate',
        name: 'eyesightRecordList',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__eyesight__eyesightRecord" */ '../eyesight/eyesightRecord'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../eyesight/eyesightRecord').default,
        exact: true,
      },
      {
        path: '/app/height/page',
        name: 'heightPage',
        icon: 'file-text',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__height__index" */ '../height/index'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../height/index').default,
        exact: true,
      },
      {
        path: '/app/height-record/list/:operate',
        name: 'heightRecordList',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__height__heightRecord" */ '../height/heightRecord'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../height/heightRecord').default,
        exact: true,
      },
      {
        path: '/app/marketing/activity-list',
        name: 'marketingActivityList',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__marketing__activityPage" */ '../marketing/activityPage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../marketing/activityPage').default,
        exact: true,
      },
      {
        path: '/app/marketing/activity-add-edit/:operate',
        name: 'activity-add-edit',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__marketing__activity-add-edit" */ '../marketing/activity-add-edit'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../marketing/activity-add-edit').default,
        exact: true,
      },
      {
        path: '/app/customer/tagClassify',
        name: 'customerTagClassify',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__customerTag__tagManager" */ '../customerTag/tagManager'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../customerTag/tagManager').default,
        exact: true,
      },
      {
        path: '/app/customer/tagGroup',
        name: 'customerTagGroup',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__customerTag__tagGroup" */ '../customerTag/tagGroup'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../customerTag/tagGroup').default,
        exact: true,
      },
      {
        path: '/app/customer/TagDataPage',
        name: 'customerTagDataPage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__customerTag__customerTagPage" */ '../customerTag/customerTagPage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../customerTag/customerTagPage').default,
        exact: true,
      },
      {
        path: '/app/customer/EnforcedPage',
        name: 'customerEnforcedPage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__customerTag__enforcedPage" */ '../customerTag/enforcedPage'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../customerTag/enforcedPage').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/login',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__login" */ '../login'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../login').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/DDLogin',
    redirect: '/login',
    exact: true,
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import(/* webpackChunkName: "p__404" */ '../404'),
          LoadingComponent: require('/home/www/.jenkins/workspace/sns-admin-h5/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
