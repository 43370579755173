import request from '@/utils/request';

// 查询教师列表
export async function queryTeacher(body: any) {
  return request({ url: 'teacher/teacherList', body });
}

// 新建教师
export async function teacherAdd(body: any) {
  return request({ url: 'teacher/teacherAdd', body });
}

// 编辑教师
export async function teacherEdit(body: any) {
  return request({ url: 'teacher/teacherEdit', body });
}

// 禁用/启用
export async function changeStatus(body: any) {
  return request({ url: 'teacher/changeStatus', body });
}

// 获取检索条件列表：所属营
export async function getCampList(body: any) {
  return request({ url: 'condition/getCampList', body });
}

// 查询督导/助教列表
export async function queryTchHelper(body: any) {
  return request({ url: 'tchHelper/list', body })
}

// 新建督导/助教
export async function tchHelperAdd(body: any) {
  return request({ url: 'tchHelper/addOrUpdate', method: 'PUT', body });
}

// 编辑督导/助教
export async function tchHelperEdit(body: any) {
  return request({ url: 'tchHelper/addOrUpdate', method: 'PUT', body });
}

// 更改督导和助教的状态
export async function tchHelperStatusUpdate(body: any) {
  return request({ url: 'tchHelper/changeStatus', method: 'PUT', body })
}{
}
