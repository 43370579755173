import { queryTeacher } from '../services/teacher';
import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: any) => T) => T },
) => void;

export interface ModelType {
  namespace: string;
  state: any;
  effects: {
    fetch: Effect;
  };
  reducers: {
    save: Reducer;
  };
}
const Model: ModelType = {
  namespace: 'teacher',

  state: {
    table: [],
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(queryTeacher, payload);
      yield put({
        type: 'save',
        payload: response,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        table: (action.payload && action.payload.list) || [],
      };
    },
  },
};

export default Model;
