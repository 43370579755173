import { useEffect, useState, useRef } from 'react';
import { parse } from 'qs';
import { notification } from 'antd';
import { Column } from '@/types/common';
import project from './utils.project';

function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

function setLoginInfo(userInfo: any) {
  localStorage.setItem('token', userInfo.token);
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

const isUrl = (path: string): boolean => reg.test(path);

const isAntDesignPro = (): boolean => false;

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
const isAntDesignProOrDev = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    return true;
  }
  return isAntDesignPro();
};

// 成功弹窗
const success = function(text = '操作成功') {
  notification.success({
    message: text,
  });
};

// 失败弹窗
const warning = function(text = '服务器异常') {
  notification.warning({
    message: text,
  });
};

// 警告弹窗
const error = function(text = '服务器异常') {
  notification.error({
    message: text,
  });
};

// 金币：分-> 元
const handleRMB = (num: number) => (num ? (num / 100).toFixed(2) : '-');

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

// 随机字符串
function randomString(length: number) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; ) {
    result += chars[Math.floor(Math.random() * chars.length)];
    i -= 1;
  }
  return result;
}

// 为每个元素增加空占位符 '-'
const columnFilter = (column: Column[], text: string) => {
  let newColumn = [];
  newColumn = column.map(item => {
    if (!item.render) {
      item.render = (val: any) => {
        if (typeof val === 'string') {
          return val && val.replace(/\s/g, '') ? val : '-';
        }
        if (val === null || val === undefined) {
          return '-';
        }
        return val;
      };
    }
    return item;
  });
  if (text !== 'none') {
    newColumn.unshift({
      title: text || '#',
      key: 'indexOrder',
      className: 'indexOrder',
      render: (a: any, b: any, c: any) => c + 1,
    });
  }
  return newColumn;
};

interface Status {
  value: string | number;
  label: string;
}
// 为每个元素增加空占位符 '-'
const handleStatusMap = (map: Status[], val: any) => {
  const item = map.find(i => i.value === val);
  return (item && item.label) || '未知类型';
};

// 复制
function copy(message: string) {
  const input = document.createElement('input');
  input.value = message;
  // input.style.height = '0px';
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, input.value.length);
  document.execCommand('Copy');
  document.body.removeChild(input);
}
// 下载图片
function getImageBlob(url: string, name: string) {
  const xhr = new XMLHttpRequest();
  xhr.open('get', url, true);
  xhr.responseType = 'blob';
  // eslint-disable-next-line func-names
  xhr.onload = function() {
    if (this.status === 200) {
      // 这里面可以直接通过URL的api将其转换，然后赋值给img.src
      // 也可以使用下面的preView方法将其转换成base64之后再赋值
      const src = URL.createObjectURL(this.response);
      imgToBase64(src, name);
    }
  };
  xhr.send();
}
function imgToBase64(src: string, name: string) {
  const img = new Image();
  img.src = src;
  img.setAttribute('crossorigin', ' *');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  img.onload = () => {
    canvas.height = img.height;
    canvas.width = img.width;
    if (ctx) ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    downloadImg(dataURL, name);
  };
}
function downloadImg(url: string, name: string) {
  const qrLinkEl = document.createElement('a');
  qrLinkEl.setAttribute('href', url);
  qrLinkEl.setAttribute('download', name ? name + '.png' : 'qr.png');
  qrLinkEl.click();
  qrLinkEl.removeAttribute('click');
}

// 导出stream表格
function exportExcel(stream: any, filename: any) {
  const a = document.createElement('a');
  const blob = new Blob([stream], { type: 'application/vnd.ms-excel' });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

/**
 * 转化时间格式
 * 2019-08-29T02:15:08.000+0000转化为2019-08-29 02:15:08
 */

function timeFormat(time: { substr: (arg0: number, arg1: number) => string | number | Date }) {
  const d = new Date(time.substr(0, 19)); // 加入substr(0, 19)处理兼容ios报错NAN
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();

  const hh = d.getHours();
  const mm = d.getMinutes();
  const ss = d.getSeconds();

  let clock = `${year}-`;

  if (month < 10) {
    clock += '0';
  }

  clock += `${month}-`;

  if (day < 10) clock += '0';

  clock += `${day} `;

  if (hh < 10) clock += '0';

  clock += `${hh}:`;
  if (mm < 10) clock += '0';
  clock += `${mm}:`;

  if (ss < 10) clock += '0';
  clock += ss;
  return clock;
}

/**
 * 将时间转化为时间戳
 * 将2019-08-29 02:15:08转化为 时间戳
 */

function timestampFormat(time: { replace: (arg0: RegExp, arg1: string) => void }) {
  const thisTime = time.replace(/-/g, '/');
  // @ts-ignore
  const timestamp = new Date(thisTime);
  const timer = timestamp.getTime();
  return timer;
}

//
function getJsonLength(json: any) {
  var jsonLength = 0;
  for (let i in json) {
    jsonLength++;
  }
  return jsonLength;
}

function isMobile(s: string) {
  return /^1[0-9]{10}$/.test(s);
}

function getHandledValue(num: number) {
  return num < 10 ? `0${num}` : num;
}
//时间戳转格式化的时间
function getFormatDate(newDate: number, startType: string) {
  const d = new Date(newDate);
  const year = d.getFullYear();
  const month = getHandledValue(d.getMonth() + 1);
  const date = getHandledValue(d.getDate());
  const hours = getHandledValue(d.getHours());
  const minutes = getHandledValue(d.getMinutes());
  const second = getHandledValue(d.getSeconds());
  let resStr = '';
  if (startType === 'year')
    resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second;
  else if (startType === 'date') resStr = year + '-' + month + '-' + date;
  else resStr = month + '-' + date + ' ' + hours + ':' + minutes;
  return resStr;
}

// useState增加回调函数
function useCallbackState(od: any) {
  const cbRef: any = useRef();
  const [data, setData] = useState(od);

  useEffect(() => {
    cbRef.current && cbRef.current(data);
  }, [data]);

  return [
    data,
    function(d: any, callback: any) {
      cbRef.current = callback;
      setData(d);
    },
  ];
}

// size {Number}文件大小
// pointLength {Number} [可选] [默认值: 2] 精确到的小数点数。
// [units=[ {Array}'B', 'K', 'M', 'G', 'TB' ]] 单位数组。从字节，到千字节，一直往上指定。如果单位数组里面只指定了到了K(千字节)，同时文件大小大于M, 此方法的输出将还是显示成多少K.
function formatFileSize(size: number, pointLength: number, units: [string]) {
  const tmpUnits = units || ['B', 'KB', 'MB', 'G', 'TB'];
  let tmpSize = size;
  let unit = tmpUnits.shift();
  while (unit && tmpSize > 1024) {
    tmpSize /= 1024;
    unit = tmpUnits.shift();
  }
  return `${unit === 'B' ? tmpSize : tmpSize.toFixed(pointLength || 2)}${unit}`;
}

function second2HMS(d: string, isFull: boolean) {
  if (!d || parseInt(d, 10) <= 0) {
    return '00:00';
  }
  const di = parseInt(d, 10);
  const h = Math.floor(di / 3600);
  const m = Math.floor((di % 3600) / 60);
  const s = Math.floor((di % 3600) % 60);
  let times = '';
  function format(num: number) {
    let val = '0';
    if (num > 0) {
      if (num >= 10) {
        val = num.toString();
      } else {
        val = `0${num}`;
      }
    } else {
      val = '00';
    }
    return val;
  }
  const hr = format(h);
  const min = format(m);
  const sec = format(s);
  if (isFull) {
    if (parseInt(hr, 10) > 0) {
      times = `${hr}:${min}:${sec}`;
    } else {
      times = `00:${min}:${sec}`;
    }
  } else {
    if (parseInt(hr, 10) > 0) {
      times = `${hr}:${min}:${sec}`;
    } else {
      times = `${min}:${sec}`;
    }
  }
  return times;
}

function formatNumber(num) {
  // 将输入的参数转换为数字类型
  const newNum = Number(num);
  // 如果转换后不是数字，则返回空字符串
  if (isNaN(newNum)) {
    return '0.00';
  }
  // 使用 toFixed() 方法保留两位小数
  let str = newNum.toFixed(2);
  // 使用 padStart() 方法在小数点后不足两位的地方自动填充 0
  str = str.padStart(str.indexOf('.') + 3, '0');
  // 返回字符串类型的结果
  return str;
}
//判断是否是pc端
function IsPC() {
  var userAgentInfo = navigator.userAgent; // 包含有关浏览器的信息
  // 非手机端
  var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
  var flag = true;
      for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;        
          }    
      }  
  return flag;
}
export {
  project,
  isAntDesignProOrDev,
  isAntDesignPro,
  isUrl,
  getPageQuery,
  setLoginInfo,
  success,
  error,
  warning,
  getBase64,
  handleRMB,
  randomString,
  columnFilter,
  handleStatusMap,
  copy,
  getImageBlob,
  exportExcel,
  timeFormat,
  timestampFormat,
  getJsonLength,
  isMobile,
  getFormatDate,
  useCallbackState,
  formatFileSize,
  second2HMS,
  formatNumber,
  IsPC
};
