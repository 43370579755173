// 七牛上传地址
// exports.QN_UPLOAD_DOMAIN = 'https://up.qiniu.com';
// up-z0.qiniup.com
// exports.QN_UPLOAD_DOMAIN = 'https://up-z2.qiniup.com';
exports.QN_UPLOAD_DOMAIN = 'https://up-z0.qiniup.com';
// 七牛回显地址
exports.QN_CDN_DOMAIN = 'https://qn.jingshizhihui.com/';
// exports.QN_CDN_DOMAIN = 'https://qn.erwntech.cn/';
// 七牛视频地址
// exports.QN_VIDEO_DOMAIN = 'https://video.feierlaiedu.com/bcxt/';
exports.QN_VIDEO_DOMAIN = 'https://qn.jingshizhihui.com/';
// exports.QN_VIDEO_DOMAIN = 'https://qn.erwntech.cn/';
// 七牛文件地址
exports.QN_CDN_KEY = 'jiaivideo';
// 视频校验正则
exports.VIDEO_TYPE = '.(mp4|flv|avi|rmvb|wmv|mpg|rm|mpeg|mkv)$';
// 默认分页配置
exports.DEFAULT_PAGINATION = {
  pageIndex: 1,
  pageSize: 20,
  showSizeChanger: true,
  total: 0,
  showTotal: total => `共 ${total} 条 `,
};

exports.BAOBAO_DOMAIN = 'https://testbbdzw.kakahui.net';
