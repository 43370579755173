/*
 * @Author: blacker
 * @Created: 2019-08-05 22:43:37
 * @LastEditTime: 2019-08-12 14:28:35
 */
export default {
  'menu.admin.role-add': '添加角色',
  'menu.fission.cash': '提现统计',
  'menu.fission.invite': '邀请统计',
  'menu.fission': '裂变',
  'menu.holiday': '节假日',
  'menu.course.expend_link': '推广链接',
  'menu.course.build_tool': '打包工具',
  'menu.course.course_list': '系列课列表',
  'menu.class.class_chart': '班级成绩',
  'menu.student': '学员',
  'menu.audio': '音频管理',
  'menu.consultant': '资产顾问',
  'menu.consultant.manage': '顾问管理',
  'menu.consultant.members': '名下会员',
  'menu.welcome': '用户',
  'menu.setting': '配置',
  'menu.lesson': '单课',
  'menu.homework': '作业',
  'menu.question': '题库',
  'menu.class.adjust': '班级调整',
  'menu.class.detail': '班级信息',
  'menu.class': '班级',
  'menu.order': '订单',
  'menu.upload': '上传文件',
  'menu.course': '系列课',
  'menu.course.send': '赠课',
  'menu.course_add_edit': '操作',
  'menu.course_manage': '课程管理',
  'menu.camp': '期',
  'menu.teacher': '教师',
  'menu.customer': '用户',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.account.password': '修改密码',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  'menu.throw': '投放管理',
  'menu.throw.manage': '投放渠道管理',
  'menu.throw.pagemanage': '落地页链接管理',
  'menu.throw.channel': '渠道管理',
  'menu.link': '链接管理',
    'menu.tuan': '拼团',
    'menu.tuan.list': '拼团管理',
    'menu.tuan.order': '拼团订单',
};
