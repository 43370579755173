import {getInsurance, exportInsuranceData, updateInsuranceStatus, queryTeacherList} from '@/services/insurance';
import { error, success } from '@/utils/utils';
import BASE from '../../env/base'

export default {
  namespace: 'insurance',
  state: {
    pagination: (<any>window).GLOBAL.DEFAULT_PAGINATION, // eslint-disable-line no-undef
    insuranceList: [],
    teacherList: [],
  },

  effects:
    {
      // 查询保险列表
      * insuranceList({ payload }: any, { call, put }: any) {
        const response = yield call(getInsurance, payload);
        const { data } = response;
        const { dataList, totalCount } = data;
        if (response.code === 0) {
          yield put({
            type: 'redList',
            payload: {
              insuranceList: dataList,
              pagination: {
                ...payload,
                total: totalCount,
              },
            },
          })
        }
      },

      // 导出保险数据
      * exportInsurance({ payload }: any, { call }: any) {
        try {
          const response = yield call(exportInsuranceData, payload);
          const url = `${BASE.QN_CDN_DOMAIN}${response.data}`;
          window.location.href = url;
          success('导出成功')
        } catch (e) {
          error('导出失败');
        }
      },

      // 更改状态
      * updateStatus({ payload }: any, { call }: any) {
        const response = yield call(updateInsuranceStatus, payload);
        if (response.code === 0) {
          return true
        }
          return false
      },

      // 保险查询教师列表
      * getTeacher({ payload }: any, { call, put }: any) {
        const response = yield call(queryTeacherList, payload);
        if (response.code === 0) {
          yield put({
            type: 'redList',
            payload: {
              teacherList: response.data,
            },
          })
        }
      },
    },

  reducers: {
    redList(state: any, action: { payload: any; }) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
