import request from '@/utils/request';

// 查询教师列表
export async function queryTeacher(body: any) {
  return request({ url: 'teacher/teacherList', body });
}

// 新建教师
export async function teacherAdd(body: any) {
  return request({ url: 'teacher/teacherAdd', body });
}

// 编辑教师
export async function teacherEdit(body: any) {
  return request({ url: 'teacher/teacherEdit', body });
}

// 禁用/启用
export async function changeStatus(body: any) {
  return request({ url: 'teacher/changeStatus', body });
}

// 获取检索条件列表：所属营
export async function getCampList(body: any) {
  return request({ url: 'condition/getCampList', body });
}

// 直播的禁用/启用
export async function liveChangeStatus(body: any) {
  return request({ url: 'teacher/teacherEdit', body });
}

// 查询姓名是否重复
export async function InquireAbout(body: any) {
  return request({ url: 'teacher/isNameRepeat', body });
}

// 获取负责人信息
export async function getPartyManagerList(body: any) {
  // const url = body.id ? `teacherDept/deptAdminList/${body.id}` : 'teacherDept/deptAdminList'
  // return request({ url: url});
  return request({ url: 'teacherDept/deptAdminList', body });
}

export async function getGroupManagerList(body: any) {
  // const url = body.id ? `teacherDept/groupAdminList/${body.id}` : 'teacherDept/groupAdminList'
  // return request({ url: url});
  return request({ url: 'teacherDept/groupAdminList', body });
}

// 获取部门与组信息
export async function getPartyAndGroup(body: any) {
  return request({ url: 'teacherDept/deptGroupTree', body });
}

// 活动管理获取组信息
export async function getActivityPartyAndGroup(body: any) {
  return request({ url: 'teacherDept/authDeptGroupTree', body });
}

// 部门添加
export async function addParty(body: any) {
  return request({ url: 'teacherDept/addDept', body });
}

// 部门编辑
export async function updateParty(body: any) {
  return request({ url: 'teacherDept/addDept', body });
}

// 部门删除
export async function delParty(body: any) {
  return request({ url: `teacherDept/deleteDept/${body.id}` });
}

// 组添加
export async function addGroup(body: any) {
  return request({ url: 'teacherDept/addGroup', body });
}

// 组编辑
export async function updateGroup(body: any) {
  return request({ url: 'teacherDept/addGroup', body });
}
// 组删除
export async function delGroup(body: any) {
  return request({ url: `teacherDept/deleteGroup/${body.id}` });
}
// 班主任列表
export async function liveTeacherList(body: any) {
  return request({ url: 'teacher/listLiveTeacher', body });
}
//重置活码
export async function resetCode(body: any) {
  return request({ url: `teacher/resetCode/${body.id}`  });
}
//获取企微主体接口
export async function systemConfig(body: any) {
  return request({ url: 'systemConfig/jsonArrayByCode',body});
}
