import request from '@/utils/request';

// 分行查询直播课,按创建时间倒序排序
export async function getLives(body: any) {
  return request({ url: 'liveStudio/selectLiveStudiosV2', body });
}

// 添加直播课程
export async function createLive(body: any) {
  return request({ url: 'liveStudio/createLiveStudio', body });
}

// 编辑课程关联班级ids
export async function editLive(body: any) {
  return request({ url: 'liveStudio/updateClassIds', body });
}

// 查询直播课程访客列表
export async function getLiveMembers(body: any) {
  return request({ url: 'liveStudio/selectVistors', body });
}

// 异步通过直播间id查询关联的班级学员听课统计
export async function selectVistorCountByTalkFunCourseId(body: any) {
  return request({ url: 'liveStudio/selectVistorCountByTalkFunCourseId', body });
}

// 导出直播课程访客列表
export async function exportMember(body: any) {
  return request({ url: 'liveStudio/exportVistors', body });
}

// 直播班级列表
export async function getLiveClass(body: any) {
  return request({ url: 'lives/listClass', body });
}

// 删除直播班级
export async function deleteLiveClass(body: any) {
  return request({ url: `lives/deleteClass/${body.classId}` });
}

// 直播班级列表
export async function addLiveClass(body: any) {
  return request({ url: 'lives/addUpdateClass', method: 'PUT', body });
}

// 根据班主任id获取其关联的销售账号
export async function getAccountInfoByTeacherId(body: any) {
  return request({ url: `lives/getAccountInfoByTeacherId/${body.teacherId}` });
}

// 直播班级列表
export async function getLiveClassDetail(body: any) {
  return request({ url: `lives/classDetail/${body.id}` });
}

// 更新单个二维码
export async function updateQrcode(body: any) {
  return request({ url: 'lives/renewGroup', method: 'PUT', body });
}

// 查询直播期列表
export async function getLiveTerm(body: any) {
  return request({ url: 'lives/listCampDate', body });
}

// 查询直播期详情
export async function queryEditLiveTerm(body: any) {
  return request({ url: `lives/campDateDetail/${body}` });
}

// 更新直播期
// export async function updateLiveTerm(body: any) {
//   return request({ url: 'lives/addUpdateCampDate', method: 'PUT', body });
// }

// 更新直播期
export async function updateLiveTerm(body: any) {
  return request({ url: 'lives/newAddUpdateCampDate', method: 'PUT', body });
}

// 查询打包推广课
export async function queryPackageCourses(body: any) {
  return request({ url: 'lives/packageCourses', body });
}

// 推广课打开弹窗
export async function openPopups(body: any) {
  return request({ url: 'lives/openPopups', body });
}

// 推广课关闭弹窗
export async function closePopups(body: any) {
  return request({ url: 'lives/closePopups', body });
}

// 推广课打开悬浮窗
export async function openFloat(body: any) {
  return request({ url: 'lives/openFloat', body });
}

// 推广课关闭悬浮窗
export async function closeFloat(body: any) {
  return request({ url: 'lives/closeFloat', body });
}

// 推广课打开弹幕
export async function openBrrage(body: any) {
  return request({ url: 'lives/openBrrage', body });
}

// 推广课关闭弹幕
export async function closeBrrage(body: any) {
  return request({ url: 'lives/closeBrrage', body });
}

// 上传直播平台访客excel重新统计访客
export async function uploadExcel(body: any) {
  return request({ url: 'liveAndVisitors/uploadSeverWatchers', body, type: 'form-data' });
}

// 上传直播平台聊天excel
export async function uploadExcelForWechat(body: any) {
  return request({ url: 'liveAndVisitors/importChatContentExcel', body, type: 'form-data' });
}

// 导出直播访客班级学员统计
export async function exportVistors(body: any) {
  return request({ url: 'liveAndVisitors/downloadLiveWatchers', body });
}

// 查询直播学员数据
export async function queryLiveStudent(body: any) {
  return request({ url: 'liveClassMember/selectLiveClassMembers', body });
}

// 查询直播学员的课程发言统计列表
export async function queryLiveStudentChatInfo(body: any) {
  return request({ url: 'liveClassMember/selectClassMemberLiveChatInfo', body });
}

// 查询直播学员的课程详细发言统计列表
export async function queryLiveStudentChatInfoDetail(body: any) {
  return request({ url: 'liveClassMember/selectContentsByCourseIdAndCustomerId', body });
}

// 查询直播学员课程回访信息
export async function queryPlayBackInfo(body: any) {
  return request({ url: 'liveClassMember/selectClassMemberPlayBackInfo', body });
}

// 获取关联群列表
export async function getWechatGroups(body: any) {
  return request({ url: 'lives/listWechatGroup', body });
}

// 下载回放视频
export async function downloadPlayback(body: any) {
  return request({ url: 'lives/downloadPlaybackByCourse', body });
}

// 重新下载
export async function downloadResetPlayback(body: any) {
  return request({ url: 'lives/reDownloadPlaybackByCourse', body });
}

// 导出学员表
export async function exportExcelLiveStudent(body: any) {
  return request({ url: 'liveClassMember/exportLiveClassMembers', body, errToast: false });
}

// 获取直播订单列表
export async function queryOrder(body: any) {
  return request({ url: 'order/listLiveOrder', body });
}

// 发起推送
export async function sendPush(body: any) {
  return request({ url: 'lives/remidClassSelf', body });
}

// 查询用户评级标准
export async function queryUserGrade(body: any) {
  return request({ url: 'liveClassMember/selectTagTypeList', body });
}

// 根据班级id查询微信群
export async function queryWxGroups(body: any) {
  return request({ url: 'liveClassMember/selectLiveGroupsByLiveClassId', body });
}

// 用户所属班级
export async function listBeforeLiveEntryClassName(body: any) {
  return request({ url: 'order/listBeforeLiveEntryClassName', body });
}

// 用户所属班级
export async function listBeforeLiveEntryClassNameNew(body: any) {
    return request({ url: 'order/listLiveOrderDetail', body });
}
// 导出订单列表(带模糊查询)
export async function exportLiveOrderList(body: any) {
  return request({ url: 'order/exportLiveOrderList', body });
}
// 删除群组
export async function deleteQrcode(body: any) {
  return request({ url: `lives/deleteGroup/${body.id}`, method: 'PUT' });
}

// 查询个人关联群组
export async function checkSignGroups(body: any) {
  return request({ url: `lives/queryPersonalGroup/${body.id}`, method: 'PUT' });
}

// 修改个人关联群组
export async function updateSignGroups(body: any) {
  return request({ url: 'lives/renewPersonalGroup', body, method: 'PUT' });
}

// 修改订单的归属
export async function revampOrderBelong(body: any) {
  return request({ url: 'order/modifyOrderSource', body, method: 'POST' });
}

// 下载问卷调查
export async function downloadQuestions(body: any) {
  return request({ url: 'lives/downloadExcelOfLiveRoomSurvey', body, errToast: false });
}

// 查询直播期列表
export async function getLiveInter(body: any) {
  return request({ url: 'liveOperation/selectLiveOperationList', body });
}

// 根据期id更换直播源
export async function updateCurrentLiveLine(body: any) {
  return request({ url: 'liveOperation/changeLiveLine', body });
}

// 切换直播类型
export async function changeIfLiveRecord(body: any) {
  return request({ url: 'liveOperation/changeIfLiveRecord', body });
}

// 切换直播类型
export async function switchOnStatus(body: any) {
  return request({ url: 'liveOperation/switchOnStatus', body });
}

// 根据期id更换样式
export async function changeLiveVersion(body: any) {
  return request({ url: 'liveOperation/changeLiveVersion', body });
}

// 获取随机名字一组20个
export async function randomVistors(body: any) {
  return request({ url: 'liveOperation/randomVistors80', body });
}

// 虚拟报名
export async function virtualSignUp(body: any) {
  return request({ url: 'liveOperation/virtualSignUp', body });
}

// 批量虚拟报名
export async function batchVirtualSignUp(body: any) {
  return request({ url: 'liveOperation/batchVirtualSignUp', body });
}

// 直播间重构推送消息
export async function publishLiveCampDateMessage(body: any) {
  return request({ url: 'liveOperation/publishLiveCampDateMessage', body });
}

// 获取禁词列表
export async function queryWords(body: any) {
  return request({ url: 'liveBlockWord/select', body });
}

// 添加禁词
export async function addWords(body: any) {
  return request({ url: 'liveBlockWord/create', body });
}

// 删除禁词
export async function removeWords(body: any) {
  return request({ url: 'liveBlockWord/delete', body });
}

// 直播间互动中直播间一键开启关闭弹窗获取打包课
export async function packageCoursesByTalkFunCourseId(body: any) {
  return request({ url: 'liveOperation/packageCoursesByTalkFunCourseId', body });
}

// 直播间互动中直播间一键开启关闭弹窗
export async function oneKeyPopupRedPacket(body: any) {
  return request({ url: 'liveOperation/oneKeyPopupRedPacket', body });
}

// 回放列表
export async function selectLiveStudioList(body: any) {
  return request({ url: 'liveCourseVideo/selectLiveStudioList', body });
}

// 编辑回放列表
export async function redpackConfigUpsert(body: any) {
  return request({ url: 'liveCourseVideo/redpackConfigUpsert', body });
}

// 查询实时直播间消息
export async function selectRealTimeMessageList(body: any) {
  return request({ url: 'liveChatRealtimeMessage/selectRealTimeMessageList', body });
}

// 添加直播实时消息关键词
export async function createKeyWord(body: any) {
  return request({ url: 'liveChatRealtimeMessage/createKeyWord', body });
}

// 查询直播实时消息关键词
export async function selectKeyWordList(body: any) {
  return request({ url: 'liveChatRealtimeMessage/selectKeyWordList', body });
}

// 删除直播实时消息关键词
export async function deleteKeyWord(body: any) {
  return request({ url: 'liveChatRealtimeMessage/deleteKeyWord', body });
}

// 下载视频
export async function preDownloadVideo(body: any) {
  return request({ url: 'liveCourseVideo/preDownloadVideo', body });
}

// 查询下载进度
export async function selectTransferProgress(body: any) {
  return request({ url: 'liveCourseVideo/selecTransferProgress', body });
}

// 直播课程-关联直播期：查询已关联的直播期
export async function getLiveCourseGroupCampDateInfoList(body: any) {
  return request({ url: 'groupRel/getLiveCourseGroupCampDateInfoList', body });
}

// 直播课程-关联直播期：查询下拉选营期列表
export async function getCampDateForGroupRel(body: any) {
  return request({ url: 'groupRel/getCampDateForGroupRel', body });
}

// 直播课程-关联直播期：课程组关联直播期
export async function syncCampDate(body: any) {
  return request({ url: 'groupRel/syncCampDate', body });
}

// 直播课程-关联直播期：课程组关联直播期
export async function syncDeleteCampDate(body: any) {
  return request({ url: 'groupRel/syncDeleteCampDate', body });
}

// 分页查询直播课程组列表
export async function selectLiveCourseGroupList(body: any) {
  return request({ url: 'liveCourseGroup/selectLiveCourseGroupList', body });
}

// 编辑课程组
export async function upsertLiveCourseGroup(body: any) {
  return request({ url: 'liveCourseGroup/upsertLiveCourseGroup', body });
}

// 根据课程组id查询课程列表
export async function selectGroupItemList(body: any) {
  return request({ url: 'liveCourseGroup/selectGroupItemList', body });
}

// 编辑直播组单课
export async function upsetLiveCourseItem(body: any) {
  return request({ url: 'liveCourseGroup/upsetLiveCourseItem', body });
}

// 删除直播组单课
export async function deleteGroupItem(body: any) {
  return request({ url: 'liveCourseGroup/deleteGroupItem', body });
}

// 根据营期获取课程组以及课程组中的单课信息
export async function getGroupLiveCourse(body: any) {
  return request({ url: `lives/getGroupLiveCourse/${body.campDateId}` });
}

// 直播间互动-发送系统消息
export async function pushLiveStudioSystemChatMessage(body: any) {
  return request({ url: 'liveOperation/pushLiveStudioSystemChatMessage', body });
}

// 解析回放地址
export async function analyzeMutilLiveVideos(body: any) {
  return request({ url: 'liveCourseVideo/analyzeMutilLiveVideos', body });
}

// 下载ppt和聊天记录
export async function downloadPptAndChatMessages(body: any) {
  return request({ url: 'liveCourseVideo/downloadPptAndChatMessages', timeout: '600000', body });
}

// 上传合并后的视频到七牛云
export async function uploadVideo(body: any, talkFunCourseId: any) {
  return request({ url: `liveCourseVideo/uploadVideo?talkFunCourseId=${talkFunCourseId}`, timeout: '1800000', type: 'form-data', body });
}

// 视频信息回传
export async function multilVideoRecover(body: any) {
  return request({ url: 'liveCourseVideo/multilVideoRecover', body });
}

// 直播间期获取关注人数
export async function statCmapDate(body: any) {
  return request({ url: 'lives/statCmapDate', body });
}

// 直播间互动-发送福利资格红包
export async function pushWelfareRedPacket(body: any) {
  return request({ url: 'liveOperation/pushWelfareRedPacket', body });
}

// 直播期-删除期
export async function deleteLiveTerm(body: any) {
    return request({ url: 'lives/deleteLiveCampDate', body });
}
