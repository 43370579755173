import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'class', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/class.ts').default) });
app.model({ namespace: 'global', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/global.ts').default) });
app.model({ namespace: 'insurance', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/insurance.ts').default) });
app.model({ namespace: 'live', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/live.ts').default) });
app.model({ namespace: 'login', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/login.ts').default) });
app.model({ namespace: 'operation', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/operation.ts').default) });
app.model({ namespace: 'research', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/research.ts').default) });
app.model({ namespace: 'setting', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/setting.ts').default) });
app.model({ namespace: 'teacher', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/teacher.ts').default) });
app.model({ namespace: 'user', ...(require('/home/www/.jenkins/workspace/sns-admin-h5/src/models/user.ts').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
